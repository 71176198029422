<template>
    <div class="stationView" style="margin-top: 50px;">
      <main class="player-content row">
        <section class="col-xl-8 col-md-12" style="margin-top: 0!important;">
<!--
    <div class="flex-item flex-1">
        <div class="card_home push-bottom fx fx-slide-up fx-delay-3">
    <div class="push-bottom" style="align-items: center !important;    justify-content: space-between !important;    display: flex !important;">
      <h5 class="text-nowrap fx fx-slide-left fx-delay-1">Nos podcasts</h5>
      <div>
        <i class="fa fa-chevron-left aleft" style="color:#fff" @click="previous"></i>
        <i class="fa fa-chevron-right aright" style="color:#fff" @click="next"></i>
      </div>
      
    </div>
    <hr style="color:#fff;border-bottom: 2px solid rgb(255 255 255);">
    <div id="test" style="display:flex"> 

<li  v-for="(podcast, index) in displayedPodcasts" :key="index" 
    :class="'fx-slide-left fx-delay-' + ( index + 2 )"
    class="card fx flex-row flex-top flex-stretch flex-1" style="margin-right: 10px !important;">

  <div class="card_background" :style="'background-image: url( '+podcast.img+' );'"></div>
  <div class="pad-left">
    <div> <span style="    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ podcast.title }}</span> </div>
    <div><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ podcast.description }}</span> </div>
  </div>
</li>


    </div>
    

  </div>
</div>

-->
<!--
<div class="card_home push-bottom fx fx-slide-up fx-delay-3">
<div class="push-bottom" style="align-items: center !important;    justify-content: space-between !important;    ">
      <h5 class="text-nowrap fx fx-slide-left fx-delay-1">Actualités</h5>     
    </div>
    <hr style="color:#fff;border-bottom: 2px solid rgb(255 255 255);">

<div class="row">

  <li  v-for="( actualite, i ) of actualites" :key="actualite.title"
    :class="'fx-slide-left fx-delay-' + ( i + 2 )"
    class="card fx flex-row flex-top flex-stretch col-md-4" style="margin-left: 5px !important;max-width: 32% !important;">

    <a :href="actualite.link" class="news-box" target="_blank">
                        <div class="news-background" :style="'background-image: url( '+actualite.media_url+' );'" style="width: 100%;
    height: 125px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 10px 10px 0px 0px;"></div>
                        <div class="body-news-box" style="    position: relative;
    font-size: 16px;
    width: 100%;
    height: 200px;
    color: #fff;
    padding: 20px;
    ">
                            <div class="news-title" style="font-size: 18px;
    width: 100%;
    font-family: 'text-regular';
    text-decoration: none;
    margin-bottom: 11px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;">{{ actualite.title }}</div>
                            <div class="news-content" style="font-size: 16px;
    font-family: 'text-light-regular';
    width: 100%;
    text-decoration: none;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;">{{ actualite.description }}</div>
                            <div class="news-time" style="font-size: 14px;
    margin-top: 18px;
    font-family: 'text-thin-regular';
    width: 100%;
    text-decoration: none;
    text-align: right;
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: auto;">Le {{ actualite.pubdate }}</div>
                        </div>
                    </a>


</li>

          

  </div>
</div>
-->

<div class="">
        <div class="card_home push-bottom fx fx-slide-up fx-delay-3">
    <div class="push-bottom" style="align-items: center !important;    justify-content: space-between !important;">
      <h5 class="text-nowrap fx fx-slide-left fx-delay-1">Emissions de ce {{ currentDay }}</h5>     
    </div>
    <hr style="color:#fff;border-bottom: 2px solid rgb(255 255 255);">
    <div class="row" style="justify-content: space-between;    padding: 10px 28px;">

<li  v-for="( program, i ) of programs" :key="program.title"
    :class="'fx-slide-left fx-delay-' + ( i + 2 )"
    class="card fx col-md-4" style="">
 
    <div class="weekly2-single slick-slide slick-active" data-slick-index="1" aria-hidden="false" style="width: 244px;margin-left: auto;margin-right: auto;" tabindex="0" >
                                            <div class="weekly2-img" style="padding: 15px;">
                                                <img :src="''+program.image+''" :alt="''+program.title+''" style="width: 100%; height: 200px; border-radius: 5px; object-fit: cover;">
                                            </div>	
											
											<a href="emissions" tabindex="0">
                                            <div class="weekly2-caption" style="padding-bottom: 5px;margin-top: 5px;">
											<center>
											
                                                <h5 style="margin-top: 5px; font-size: 16px; font-weight: bold; text-transform : uppercase; color: #fff" id="bloc_texte_principal_clips_1_2">{{ program.title }}</h5>
												<p style="margin-top: -10px; font-size: 16px; text-transform : uppercase; color: #262626" id="bloc_texte_principal_clips_2_2">{{ program.description }}</p>
												
												<div style="margin-top: 6px;"><span class="bgbeg" style="padding-bottom: 5px;border-radius: 5px;" id="elementspricipaux_fond_clips2"><b><font style="font-size:12px; color: #FFFF00; padding: 10px;" id="elementspricipaux_texte_clips2">{{ program.time }}</font></b></span></div>
												
                                            </center>
											</div></a>
                                        </div>
</li>
    </div>
  </div>
</div>

     </section>


     <section class="col-xl-4 col-md-12" style="margin-top: 0!important;">
        <div class="flex-0">
        <div class="card_home push-bottom fx fx-slide-up fx-delay-3">
    <div class="push-bottom">
      <h5 class="text-nowrap fx fx-slide-left fx-delay-1">Derniers titres diffusés</h5>
      <hr style="color:#fff;border-bottom: 2px solid rgb(255 255 255);">
    </div>
    <div v-if="!hasSongs" class="card push-bottom">
      There are no songs loaded yet for this station.
    </div>
    <ul v-if="info.length" class="player-tracklist push-bottom">

        <li  v-for="( el, i ) of info2" :key="el.titre"
          :class="'fx-slide-left fx-delay-' + ( i + 2 )"
          class="card fx flex-row flex-top flex-stretch">

        <div class="card_background" :style="'background-image: url( '+el.cover+' );'"></div>
        <div class="pad-left">
          <div> <span class="text_background text-bright">{{ el.time }}</span> </div>
          <div> <span style="    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ el.titre }}</span> </div>
          <div><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ el.artist }}</span> </div>
        </div>
      </li>

    </ul>
  </div>
</div>


<div class="flex-0 card_home push-bottom fx fx-slide-up fx-delay-3">
    <div class="push-bottom">
      <h5 class="text-nowrap fx fx-slide-left fx-delay-1">TopVote</h5>
      <hr style="color:#fff;border-bottom: 2px solid rgb(255 255 255);">
    </div>
    <div v-if="!hasSongs" class="card push-bottom">
      There are no songs loaded yet for this station.
    </div>
    <ul v-if="info.length" class="player-tracklist push-bottom">

      <li  v-for="( el, i ) of info" :key="el.title"
          :class="'fx-slide-left fx-delay-' + ( i + 2 )"
          class="card fx flex-row flex-top flex-stretch">

        <div class="card_background" :style="'background-image: url( '+el.pochette+' );'"></div>
        <div class="pad-left">
          <div> <span class="text_background text-bright">N° {{ i + 1 }}</span> </div>
          <div> <span style="    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ el.titre }}</span> </div>
          <div><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ el.artiste }}</span> </div>
        </div>
      </li>

    </ul>
  </div>

     </section>
      </main>
  
    </div>
  </template>
  
  
  
  <style scoped>
  
  </style>

<script>
import axios from 'axios';
import { mapState  } from 'vuex';
export default {
    data () {
    return {
        podcasts: [],
        actualites: [],
        currentIndex: 0,
        pageSize: 3,
        info: [], // make this an empty array
        info2: [], // make this an empty array
        days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    selectedDay: 0,
    programs: [],
    currentDay: "",
    }
},
  name: "songsHistory",
  computed: {
    ...mapState('nowplaying',{
      songs : state => state.songs,
    }),
    config(){
      return this.$store.getters["playerConfig/getConfig"];
    },
    hasSongs: (songs) => {
      return (!!Array(songs).length);
    },
    displayedPodcasts() {
          const start = this.currentIndex;
          const end = Math.min(this.currentIndex + this.pageSize, this.podcasts.length);
          return this.podcasts.slice(start, end);
    }
  },

  mounted () {
    axios
      .get('https://ythomas.fr/enkaradio/api/get_lasttitre.php?url=https://enkaradiobe.radioca.st&type=SHOUTCAST&pochette_perso=https://www.enkaradio.be/images/Enka_pictogramme.jpg')
      .then(response => (this.info2 = response.data.last_title.slice(0,3)))
    axios
      .get('https://ythomas.fr/enkaradio/api/topvote.php')
      .then(response => (this.info = response.data.slice(0,3)))
    
    const currentDay = new Date().getDay();
    this.currentDay = this.days[currentDay];
    this.selectedDay = currentDay === 0 ? 6 : currentDay - 1;

    fetch('https://ythomas.fr/enkaradio/podcast.php')
          .then(response => response.json())
          .then(data => {
            this.podcasts = data;
          })
          .catch(error => {
            console.error('Erreur lors du chargement des données:', error);
          });

    fetch('https://ythomas.fr/enkaradio/api/flux_rss.php')
          .then(response => response.json())
          .then(data => {
            this.actualites = data.item.slice(0,3);
            console.error(data);
          })
          .catch(error => {
            console.error('Erreur lors du chargement des données:', error);
          });

    fetch('https://ythomas.fr/enkaradio/api/list_programmes_jour.php')
          .then(response => response.json())
          .then(data => {
            this.programs = data;
           })
          .catch(error => {
            console.error('Erreur lors du chargement des données:', error);
          });


},

methods: {
    formatDate(dateString) {
    const options = { hour: '2-digit', minute: '2-digit' };
    const dateObj = new Date(dateString);
    return dateObj.toLocaleTimeString([], options);
  },
        next() {
          if (this.currentIndex < this.podcasts.length - this.pageSize) {
            this.currentIndex += this.pageSize;
          }
        },
        previous() {
          if (this.currentIndex >= this.pageSize) {
            this.currentIndex -= this.pageSize;
          }
        }
      }

}
</script>

<style scoped>

</style>